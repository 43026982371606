<div class="layout-topbar">
    <div class="topbar-left">
        <a #menubutton tabindex="0" class="menu-button p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-chevron-left"></i>
        </a>
        <img class="horizontal-logo" [routerLink]="['/']" [src]="'layout/images/logo-'+ logo + '.svg'"
            alt="diamond-layout" />
        <span class="topbar-separator"></span>
        <app-breadcrumb></app-breadcrumb>
        <img class="mobile-logo" [src]="'layout/images/logo-'+ logo + '.svg'" alt="diamond-layout" />
    </div>

    <div class="layout-topbar-menu-section">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-mask modal-in"></div>

    <div class="topbar-right">
        <ul class="topbar-menu">
            <li class="search-item">
                <a tabindex="0" (click)="onSearchClick()" class="p-trigger">
                    <i class="pi pi-search"></i>
                </a>
            </li>
            <li class="static sm:relative">
                <a tabindex="0" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                    leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-bell"></i>
                    <span class="topbar-badge">5</span>
                </a>
                <ul
                    class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-19rem mt-2 right-0 z-5 top-auto">
                    <li>
                        <a pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-shopping-cart mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">New Order</span>
                                <span class="text-color-secondary">You have <strong>3</strong> new orders.</span>
                            </span>
                        </a>
                        <a pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-check-square mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Withdrawn Completed</span>
                                <span class="text-color-secondary">Funds are on their way.</span>
                            </span>
                        </a>
                        <a pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-chart-line mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Monthly Reports</span>
                                <span class="text-color-secondary">Monthly Reports are ready.</span>
                            </span>
                        </a>
                        <a pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-comments mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Comments</span>
                                <span class="text-color-secondary"><strong>2</strong> new comments.</span>
                            </span>
                        </a>
                        <a pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-exclamation-circle mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Chargeback Request</span>
                                <span class="text-color-secondary"><strong>1</strong> to review.</span>
                            </span>
                        </a>
                    </li>
                </ul>
            </li>

            <li class="profile-item static sm:relative">
                <a tabindex="0" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden"
                    leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <img src="demo/images/avatar/profile.jpg" alt="diamond-layout" class="profile-image" />
                    <span class="profile-name">Amelia Stone</span>
                </a>
                <ul
                    class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-12rem mt-2 right-0  z-5 top-auto">
                    <li>
                        <a pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-user mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Profile</span>
                            </span>
                        </a>
                        <a pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-cog mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Settings</span>
                            </span>
                        </a>
                        <a pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-calendar mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Calendar</span>
                            </span>
                        </a>
                        <a pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-inbox mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Inbox</span>
                            </span>
                        </a>
                        <a pRipple
                            class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-power-off mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Logout</span>
                            </span>
                        </a>
                    </li>
                </ul>

            <li class="right-sidebar-item">
                <a tabindex="0" (click)="onRightMenuClick()">
                    <i class="pi pi-align-right"></i>
                </a>
            </li>
        </ul>
    </div>
</div>